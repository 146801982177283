import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogMeta } from "../../components/blogMeta"
import Highlight from "react-highlight.js"

export default function BlogPostRailsStarterConfigDatabasePostgresql() {
  return (
    <Layout>
      <SEO title="Rails Starter config/database.rb with PostgreSQL" />
      <main>
        <h1>Rails Starter config/database.rb with PostgreSQL</h1>
        <BlogMeta
          author="Primespot Engineering"
          published="May 26, 2020"
          tags={["Engineering", "Rails"]}
        />
        <p>
          When starting a new Rails project, an early stumbling block for me is
          setting up the database. I always find myself going back to an old
          project to remind myself of the config format.
        </p>
        <p>
          After you figure out the way to structure the file, it’s simple to get
          working. But until then, I get confused about whether the property is
          “host” or “hostname”, “user” or “username”, etc.
        </p>
        <p>
          If you are in the same boat, this starter config.yml file might be of
          use to you as a reference.
        </p>
        <p>
          Make sure you replace the magic strings with the correct input for
          your configuration.
        </p>
        <Highlight language="ruby">
          default: &default
          <br />
          &nbsp;&nbsp; adapter: postgresql
          <br />
          &nbsp;&nbsp; encoding: unicode
          <br />
          &nbsp;&nbsp; pool: &lt;%= ENV.fetch("RAILS_MAX_THREADS") {5} %&gt;
          <br />
          &nbsp;&nbsp; host: localhost
          <br />
          &nbsp;&nbsp; port: 5432
          <br />
          &nbsp;&nbsp; username: enter_database_username_here
          <br />
          &nbsp;&nbsp; password: enter_database_password_here
          <br />
          <br />
          development:
          <br />
          &nbsp;&nbsp; &lt;&lt;: *default
          <br />
          &nbsp;&nbsp; database: project_name_development
          <br />
          <br />
          test:
          <br />
          &nbsp;&nbsp; &lt;&lt;: *default
          <br />
          &nbsp;&nbsp; database: project_name_test
          <br />
          <br />
          production:
          <br />
          &nbsp;&nbsp; &lt;&lt;: *default
          <br />
          &nbsp;&nbsp; database: project_name_production
          <br />
          &nbsp;&nbsp; username: &lt;%= ENV['PROJECT_DATABASE_USERNAME'] %&gt;
          <br />
          &nbsp;&nbsp; password: &lt;%= ENV['PROJECT_DATABASE_PASSWORD'] %&gt;
          <br />
        </Highlight>
      </main>
    </Layout>
  )
}
